import { Component, OnInit } from '@angular/core';
import { HxAuthService, NotifierService } from 'hx-services';
import { AppService } from './app.service';
import { environment } from '@profile-env/environment';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { en_US, ru_RU } from 'ng-zorro-antd/i18n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

  isCollapsed = false;
  theme: 'light' | 'dark' = 'light'; // dark
  hasAppUpdate = false;
  isProd = environment.production;

  constructor(
    public auth: HxAuthService,
    private appService: AppService,
    private notifierService: NotifierService,
    private i18n: NzI18nService,
  ) {
  }

  ngOnInit(): void {
    this.appService.startUpdateChecker();
    this.appService.update.subscribe(() => this.hasAppUpdate = true);
    this.notifierService.start();
    if (this.auth.getUiLang() === 'en') {
      this.i18n.setLocale(en_US);
    } else {
      this.i18n.setLocale(ru_RU);
    }
  }

  updateApp() {
    this.appService.updateApp();
  }

  checkForUpdate() {
    this.appService.checkUpdates();
  }

  aboutApp() {
    this.appService.about();
  }
}
