import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeRuKZ from '@angular/common/locales/ru-KZ';
import localeRu from '@angular/common/locales/ru';
import localeEsUs from '@angular/common/locales/es-US';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { SharedModule } from './shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@profile-env/environment';
import {
  ApiModule,
  ComponentType,
  Configuration,
  HX_APP_VERSION,
  HX_COMPONENT_NAME,
  HX_ENV_MODE,
  HX_KEYCLOAK,
  HxAuthService,
  initKeycloak,
  TranslocoRootModule
} from 'hx-services';
import { APP_VERSION } from '@profile-env/version';
import * as Sentry from '@sentry/browser';
import { enablePatches } from 'immer';
import { TranslocoService } from '@ngneat/transloco';

registerLocaleData(localeRuKZ);
registerLocaleData(localeRu);
registerLocaleData(localeEsUs);

enablePatches();

if (environment.sentryUrl) {
  Sentry.init({
    environment: environment.production ? 'production' : 'staging',
    ignoreErrors: ['Unauthorized', 'productBalance.zero'],
    dsn: environment.sentryUrl as any,
    sampleRate: 0,
    autoSessionTracking: false,
    tracePropagationTargets: [],
    beforeSend: (event, hint) => {
      console.log('[sentry] before send', event, hint);
      if (hint && hint.originalException && hint.originalException instanceof HttpErrorResponse) {
        if (hint.originalException.status === 401) {
          console.log('[sentry] skipped error logging', event);
          return null;
        }
      }
      return event;
    },
    integrations: [
      new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false,
      }),
    ],
  });
}

function getKeycloakUrl() {
  if (environment.production) {
    return location.origin.replace(location.origin.split('//')[1].split('.')[0], 'auth') + '/auth';
  }
  return environment.keycloakUrl;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslocoRootModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ApiModule.forRoot(() => new Configuration({basePath: ''})),
  ],
  providers: [
    {
      provide: HX_COMPONENT_NAME,
      useValue: ComponentType.profile
    },
    {
      provide: HX_APP_VERSION,
      useValue: APP_VERSION
    },
    {
      provide: HX_ENV_MODE,
      useValue: environment.production
    },
    {
      provide: HX_KEYCLOAK,
      useValue: {
        url: getKeycloakUrl(),
        clientId: environment.keycloakClientId,
        realm: environment.keycloakRealm
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService, HxAuthService, HX_KEYCLOAK, TranslocoService],
    },
    { provide: LOCALE_ID, useValue: 'ru_KZ' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
